// import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './Pages/Home/Home';
import { useState } from 'react';
import Trip from './Pages/Trip/Trip';
import Kitecamp from './Pages/Kitecamps/Kitecamp';
import Contactus from './Pages/Contactus/Contactus';
import ScrollToTop from './Components/ScrollToTop';
import Levels from './Form/Levels';
import Datepicker from './Form/Datepicker';
import Room from './Form/Room';
import Activity from './Form/Activity';
import Checkout from './Form/Checkout';
import Userinfo from './Form/Userinfo';
import KiteLayout from './Pages/kiteLayout';
import Aff_Layout from './Affiliate/Aff_Layout';
import Aff_Dashboard from './Affiliate/Aff_Dashboard';
import NewAffiliate from './Affiliate/NewAffiliate';
import MyCreative from './Affiliate/MyCreative';
import Formlayout from './Form/Formlayout';

import Stories from './Pages/Stories/Stories';
import School from './Pages/School/School';
import Discoverkiteactive from './Pages/Discoverkite/Discoverkiteactive';
import Kiteactiveteam from './Pages/Kiteactiveteam/Kiteactiveteam';
import Becomepartner from './Pages/Becomaparter/Becomepartner';
import Dentvtas from './Pages/Stories/Storiespage/Dentvtas';
import Teamrider from './Pages/Stories/Storiespage/Teamrider';
import Downwinders from './Pages/Stories/Storiespage/Downwinders';
import Whyzanzibar from './Pages/Stories/Storiespage/Whyzanzibar';
import Kitegear from './Pages/Stories/Storiespage/Kitegear';
import Thankyou from './Form/Thankyou'
import BookingPayment from './Pages/Bookingpayment/BookingPayment';
import PrivacyPolicy from './Pages/Privacypolicy/PrivacyPolicy';
import Termcondition from './Pages/Termcondition/Termcondition';
import Roderick from './Pages/Kiteactiveteam/Teammembers/Roderick';
import PageTracker from './Components/PageTracker';
import MyAffiliate from './Affiliate/MyAffiliate';
import AdminLogin from './Admin/AdminLogin';
import Admin_Layout from './Admin/Admin_Layout';
import Admin_Dashborad from './Admin/Admin_Dashborad';
import Admin_Level from './Admin/Admin_Level';
import Admin_Add_Level from './Admin/Admin_Add_Level';
import Admin_DateRange from './Admin/Admin_DateRange';
import Admin_Add_DateRange from './Admin/Admin_Add_DateRange';
import Admin_Edit_DateRange from './Admin/Admin_Edit_DateRange';
import Admin_Room from './Admin/Admin_Room';
import Admin_Add_Room from './Admin/Admin_Add_Room';
import Admin_Edit_Room from './Admin/Admin_Edit_Room';
import Admin_Activity from './Admin/Admin_Activity';
import Admin_Add_Activity from './Admin/Admin_Add_Activity';
import Admin_Edit_Activity from './Admin/Admin_Edit_Activity';
import Admin_Coupon from './Admin/Admin_Coupon';
import Admin_Add_Coupon from './Admin/Admin_Add_Coupon';
import Admin_Edit_Coupon from './Admin/Admin_Edit_Coupon';
import EditAffiliate from './Affiliate/EditAffiliate';
import NewCreative from './Affiliate/NewCreative';
import Admin_TripLink from './Admin/Admin_TripLink';
import Calendar from './Pages/Calendar/Calendar';
import Admin_Add_TripLink from './Admin/Admin_Add_TripLink';
import Admin_Edit_TripLink from './Admin/Admin_Edit_TripLink';
import Admin_Overview from './Admin/Admin_Overview';
import Admin_Edit_Overview from './Admin/Admin_Edit_Overview';
import Admin_Kitespot from './Admin/Admin_Kitespot';
import Admin_Edit_Kitespots from './Admin/Admin_Edit_Kitespots';
import Admin_Accommodation from './Admin/Admin_Accommodation';
import Admin_Edit_Accommodation from './Admin/Admin_Edit_Accommodation';
import Admin_Myteam from './Admin/Admin_Myteam';
import Admin_Edit_Myteam from './Admin/Admin_Edit_Myteam';
import Kitemember from './Pages/Kiteactiveteam/Kitemember';
import Admin_Hosted from './Admin/Admin_Hosted';
import Admin_Package from './Admin/Admin_Package';
import Admin_Add_Package from './Admin/Admin_Add_Package';
import Admin_Edit_Package from './Admin/Admin_Edit_Package';
import Admin_Included from './Admin/Admin_Included';
import Admin_Add_Included from './Admin/Admin_Add_Included';
import Admin_Edit_Included from './Admin/Admin_Edit_Included';
import Admin_ActivityTab from './Admin/Admin_ActivityTab';
import Admin_Add_ActivityTab from './Admin/Admin_Add_ActivityTab';
import Admin_Videosection from './Admin/Admin_Videosection';
import Admin_Home from './Admin/Admin_Home';
import Admin_VideoUpload from './Admin/Admin_VideoUpload';
import Admin_Add_ActivityPage from './Admin/Admin_Add_ActivitesPage';
import Admin_ActivityPage from './Admin/Admin_ActivityPage';
import Admin_Edit_ActivityPage from './Admin/Admin_Edit_ActivityPage';
import ActivityPage from './Pages/ActivityPage';
import Admin_StoriesPage from './Admin/Admin_StoriesPage';
import Admin_Add_StoriesPage from './Admin/Admin_Add_StoriesPage';
import Admin_Edit_StoriesPage from './Admin/Admin_Edit_StoriesPage';
import Storiespage from './Pages/Stories/Storiespage';
import User_Dashboard from './Affiliate/User_Dashboard';
import User_Creative from './Affiliate/User_Creative';
import AffiliateLogin from './Affiliate/AffiliateLogin';
import ProtectedRoute from './Components/ProtectedRoute';
import User_NewCreative from './Affiliate/User_NewCreative';
import AffiliateRegister from './Affiliate/AffiliateRegister';
import EditCreative from './Affiliate/EditCreative';
import User_EditCreative from './Affiliate/User_EditCreative';
import Admin_Blog from './Admin/Admin_Blog';
import Admin_Add_Blog from './Admin/Admin_Add_Blog';
import Admin_Edit_Blog from './Admin/Admin_Edit_Blog';
import Blog from './Pages/Blog/Blog';
import BlogPage from './Pages/Blog/BlogPage';

function App() {

  const [selectedLanguage, setSelectedLanguage] = useState('en');

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <PageTracker>
          <Routes>
            <Route path='/' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Home selectedLanguage={selectedLanguage} />
              </KiteLayout>
            } />



            <Route exact path="/trip/:trip_name" element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Trip selectedLanguage={selectedLanguage} />
              </KiteLayout>
            } />

            <Route path='/contact' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Contactus selectedLanguage={selectedLanguage} />
              </KiteLayout>
            } />
            <Route path='/School' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <School selectedLanguage={selectedLanguage} />
              </KiteLayout>
            } />

            <Route path='/stories' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Stories selectedLanguage={selectedLanguage} />
              </KiteLayout>
            } />


            {/* stories inner pages ------------------------------------ */}

            <Route path='storie/:id' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Storiespage />
              </KiteLayout>
            } />

            <Route path='/den-tv-tas' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Dentvtas selectedLanguage={selectedLanguage} />
              </KiteLayout>
            } />

            <Route path='/teamrider' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Teamrider selectedLanguage={selectedLanguage} />
              </KiteLayout>
            } />


            <Route path='/DownWinder' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Downwinders selectedLanguage={selectedLanguage} />
              </KiteLayout>
            } />

            <Route path='/WhyZanzibar' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Whyzanzibar selectedLanguage={selectedLanguage} />
              </KiteLayout>
            } />

            <Route path='/kiteGear' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Kitegear selectedLanguage={selectedLanguage} />
              </KiteLayout>
            } />



            {/* kite camp  all pages routes here  */}
            <Route path='/kitecamp' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Kitecamp />
              </KiteLayout>
            }>
            </Route>

            <Route path='/calendar' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Calendar />
              </KiteLayout>
            }>
            </Route>

            <Route path='/thankyou' element={
              <Formlayout>
                <Thankyou />
              </Formlayout>
            } />


            {/* ---------------------------------------- */}
            {/* footer pages links here */}
            {/* ---------------------------------------- */}

            <Route path='/Discover_Kiteactive' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Discoverkiteactive />
              </KiteLayout>
            }>
            </Route>

            {/* ----------------------ALL TEAM MEMBERS pages------------------------------  */}

            <Route path='/kiteactive_team' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Kiteactiveteam />
              </KiteLayout>
            }>
            </Route>

            <Route path='/kiteactive_team/:id' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Kitemember />
              </KiteLayout>
            }>
            </Route>

            <Route path='/Roderickje_Pijls' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Roderick />
              </KiteLayout>
            }>
            </Route>

            {/* ------------------------------------------ */}

            <Route path='/blog' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Blog />
              </KiteLayout>
            }>
            </Route>

            <Route path='/blog/:id' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <BlogPage />
              </KiteLayout>
            }>
            </Route>

            <Route path='/activites/:id' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <ActivityPage />
              </KiteLayout>
            }>
            </Route>

            <Route path='/Become_Partner' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Becomepartner />
              </KiteLayout>
            }>
            </Route>

            <Route path='/Booking_Payment' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <BookingPayment />
              </KiteLayout>
            }>
            </Route>


            <Route path='/PrivacyPolicy' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <PrivacyPolicy />
              </KiteLayout>
            }>
            </Route>

            <Route path='/Term_Condition' element={
              <KiteLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
                <Termcondition />
              </KiteLayout>
            }>
            </Route>


            <Route path='/:trip_name/checkout/form' element={
              <Formlayout>
                <Levels />
              </Formlayout>
            } />
            <Route path="/:tripName/checkout/datepicker" element={
              <Formlayout>
                <Datepicker />
              </Formlayout>
            } />
            <Route path="/:tripName/checkout/room" element={
              <>
                <Formlayout>
                  <Room />
                </Formlayout>
              </>
            } />
            <Route path="/:tripName/checkout/activity" element={
              <>
                <Formlayout>
                  <Activity />
                </Formlayout>
              </>
            } />
            <Route path="/:tripName/checkout/checkout" element={
              <>
                <Formlayout>
                  <Checkout />
                </Formlayout>
              </>
            } />
            <Route path="/:tripName/checkout/userinfo" element={
              <>
                <Formlayout>
                  <Userinfo />
                </Formlayout>
              </>
            } />

            <Route path="/admin/affiliate" element={
              <Admin_Layout>
                <Aff_Dashboard />
              </Admin_Layout>
            } />

            <Route path="/admin/my_affiliate" element={
              <Admin_Layout>
                <MyAffiliate />
              </Admin_Layout>
            } />

            <Route path="/admin/new_affiliate" element={
              <Admin_Layout>
                <NewAffiliate />
              </Admin_Layout>
            } />

            <Route path="/admin/edit_affiliate/:id" element={
              <Admin_Layout>
                <EditAffiliate />
              </Admin_Layout>
            } />

            <Route path="/admin/myCreative" element={
              <Admin_Layout>
                <MyCreative />
              </Admin_Layout>
            } />

            <Route path="/admin/newCreative" element={
              <Admin_Layout>
                <NewCreative />
              </Admin_Layout>
            } />

            <Route path="/admin/editCreative/:id" element={
              <Admin_Layout>
                <EditCreative />
              </Admin_Layout>
            } />
            
            <Route path="/affiliate" element={
              <>
                <AffiliateLogin />
              </>
            } />

            <Route path="/register-affiliate" element={
              <>
                <AffiliateRegister/>
              </>
            } />

            <Route path='/affiliate/home' element={
              <ProtectedRoute><Aff_Layout>
                  <User_Dashboard />
                </Aff_Layout></ProtectedRoute>
            } />

            <Route path='/affiliate/creative' element={
              <ProtectedRoute><Aff_Layout>
                <User_Creative />
              </Aff_Layout></ProtectedRoute>
            } />

            <Route path='/affiliate/add/creative' element={
              <ProtectedRoute><Aff_Layout>
                <User_NewCreative />
              </Aff_Layout></ProtectedRoute>
            } />

            <Route path='/affiliate/edit/creative/:id' element={
              <ProtectedRoute><Aff_Layout>
                <User_EditCreative />
              </Aff_Layout></ProtectedRoute>
            } />


            <Route path="/admin" element={
              <>
                <AdminLogin />
              </>
            } />

            <Route path="/admin/triplink" element={
              <Admin_Layout>
                <Admin_TripLink />
              </Admin_Layout>
            } />

            <Route path="/admin/add/triplink" element={
              <Admin_Layout>
                <Admin_Add_TripLink />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/triplink/:id" element={
              <Admin_Layout>
                <Admin_Edit_TripLink />
              </Admin_Layout>
            } />

            <Route path="/admin/myteam" element={
              <Admin_Layout>
                <Admin_Myteam />
              </Admin_Layout>
            } />

            <Route path="/admin/home" element={
              <Admin_Layout>
                <Admin_Home />
              </Admin_Layout>
            } />


            <Route path="/admin/add_on_activity" element={
              <Admin_Layout>
                <Admin_ActivityPage />
              </Admin_Layout>
            } />

            <Route path="/admin/add/add_on_activity" element={
              <Admin_Layout>
                <Admin_Add_ActivityPage />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/add_on_activity/:id" element={
              <Admin_Layout>
                <Admin_Edit_ActivityPage />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/myteam/:id" element={
              <Admin_Layout>
                <Admin_Edit_Myteam />
              </Admin_Layout>
            } />

            <Route path="/admin/stories" element={
              <Admin_Layout>
                <Admin_StoriesPage />
              </Admin_Layout>
            } />

            <Route path="/admin/add/stories" element={
              <Admin_Layout>
                <Admin_Add_StoriesPage />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/stories/:id" element={
              <Admin_Layout>
                <Admin_Edit_StoriesPage />
              </Admin_Layout>
            } />

            <Route path="/admin/blog" element={
              <Admin_Layout>
                <Admin_Blog />
              </Admin_Layout>
            } />

            <Route path="/admin/add/blog" element={
              <Admin_Layout>
                <Admin_Add_Blog />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/blog/:id" element={
              <Admin_Layout>
                <Admin_Edit_Blog />
              </Admin_Layout>
            } />

            <Route path="/admin/overview" element={
              <Admin_Layout>
                <Admin_Overview />
              </Admin_Layout>
            } />

            <Route path="/admin/videosection" element={
              <Admin_Layout>
                <Admin_Videosection />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/overview/:id" element={
              <Admin_Layout>
                <Admin_Edit_Overview />
              </Admin_Layout>
            } />

            <Route path="/admin/kitespot" element={
              <Admin_Layout>
                <Admin_Kitespot />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/kitespot/:id" element={
              <Admin_Layout>
                <Admin_Edit_Kitespots />
              </Admin_Layout>
            } />

            <Route path="/admin/accommodation" element={
              <Admin_Layout>
                <Admin_Accommodation />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/accommodation/:id" element={
              <Admin_Layout>
                <Admin_Edit_Accommodation />
              </Admin_Layout>
            } />

            <Route path="/admin/hosted" element={
              <Admin_Layout>
                <Admin_Hosted />
              </Admin_Layout>
            } />

            <Route path="/admin/packages" element={
              <Admin_Layout>
                <Admin_Package />
              </Admin_Layout>
            } />

            <Route path="/admin/add/packages" element={
              <Admin_Layout>
                <Admin_Add_Package />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/packages/:id" element={
              <Admin_Layout>
                <Admin_Edit_Package />
              </Admin_Layout>
            } />

            <Route path="/admin/included" element={
              <Admin_Layout>
                <Admin_Included />
              </Admin_Layout>
            } />

            <Route path="/admin/add/included" element={
              <Admin_Layout>
                <Admin_Add_Included />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/included/:id" element={
              <Admin_Layout>
                <Admin_Edit_Included />
              </Admin_Layout>
            } />

            <Route path="/admin/activitytab" element={
              <Admin_Layout>
                <Admin_ActivityTab />
              </Admin_Layout>
            } />

            <Route path="/admin/add/activitytab" element={
              <Admin_Layout>
                <Admin_Add_ActivityTab />
              </Admin_Layout>
            } />

            <Route path="/admin/dashboard" element={
              <Admin_Layout>
                <Admin_Dashborad />
              </Admin_Layout>
            } />

            <Route path="/admin/levels" element={
              <Admin_Layout>
                <Admin_Level />
              </Admin_Layout>
            } />

            <Route path="/admin/add/levels" element={
              <Admin_Layout>
                <Admin_Add_Level />
              </Admin_Layout>
            } />

            <Route path="/admin/dates" element={
              <Admin_Layout>
                <Admin_DateRange />
              </Admin_Layout>
            } />

            <Route path="/admin/add/dates" element={
              <Admin_Layout>
                <Admin_Add_DateRange />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/dates/:id" element={
              <Admin_Layout>
                <Admin_Edit_DateRange />
              </Admin_Layout>
            } />

            <Route path="/admin/room" element={
              <Admin_Layout>
                <Admin_Room />
              </Admin_Layout>
            } />

            <Route path="/admin/add/room" element={
              <Admin_Layout>
                <Admin_Add_Room />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/room/:id" element={
              <Admin_Layout>
                <Admin_Edit_Room />
              </Admin_Layout>
            } />

            <Route path="/admin/activity" element={
              <Admin_Layout>
                <Admin_Activity />
              </Admin_Layout>
            } />

            <Route path="/admin/add/activity" element={
              <Admin_Layout>
                <Admin_Add_Activity />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/activity/:id" element={
              <Admin_Layout>
                <Admin_Edit_Activity />
              </Admin_Layout>
            } />

            <Route path="/admin/coupon" element={
              <Admin_Layout>
                <Admin_Coupon />
              </Admin_Layout>
            } />

            <Route path="/admin/add/coupon" element={
              <Admin_Layout>
                <Admin_Add_Coupon />
              </Admin_Layout>
            } />

            <Route path="/admin/edit/coupon/:id" element={
              <Admin_Layout>
                <Admin_Edit_Coupon />
              </Admin_Layout>
            } />

            <Route path="/admin/video" element={
              <Admin_Layout>
                <Admin_VideoUpload />
              </Admin_Layout>
            } />

          </Routes>
        </PageTracker>
      </BrowserRouter>
    </div>
  );
}

export default App;
